// Vanilla JS Module Index

// common modules, included on every page
// using standard ES6 imports here because no codesplitting intended
import './modules/header'
import './modules/navigation'
import './modules/cookiebanner'

// JS Hooks have to be '.js-' prefixed classes/ids
const HOOKS = {
    accordion: '.js-accordion',
    bookmark: '.js-bookmark',
    fund: '.js-fund',
    slider: '.js-slider',
    textfield: '.js-textfield',
    selectfield: '.js-select',
    responsiveImage: '.js-responsiveimage',
    toast: '#js-toast',
    disclaimer: '.js-disclaimer'
}

// Conditionally Import Modules based on DOM Nodes
const noop = () => {}
if (document.querySelector(HOOKS.accordion)) {
    import('./modules/accordion').then(noop)
}
if (document.querySelector(HOOKS.bookmark)) {
    import('./modules/bookmark').then(noop)
}
if (document.querySelector(HOOKS.fund)) {
    import('./modules/fund').then(noop)
}
if (document.querySelector(HOOKS.textfield)) {
    import('./modules/textfield').then(noop)
}
if (document.querySelector(HOOKS.selectfield)) {
    import('./modules/selectfield').then(noop)
}
if (document.querySelector(HOOKS.slider)) {
    import('./modules/slider').then(noop)
}
if (document.querySelector(HOOKS.responsiveImage)) {
    import('./modules/responsiveImage').then(noop)
}
if (document.querySelector(HOOKS.toast)) {
    import('./modules/toast').then(noop)
}
if (document.querySelector(HOOKS.disclaimer)) {
    import('./modules/disclaimer').then(noop)
}
