// Navigation
import { createFocusTrap } from '../utils'

const SELECTORS = {
    nav: '.js-nav',
    toggleMenuBtn: '.js-nav-toggle'
}

const CLASSES = {
    bodyClass: 'no-scroll',
    isOpen: 'nav--open'
}

class Navigation {
    constructor(el) {
        this.nav = el
        this.toggleMenuBtn = this.nav.querySelector(SELECTORS.toggleMenuBtn)

        this.focusTrap = createFocusTrap(this.nav, {
            toggleElement: this.toggleMenuBtn,
            onEscape: () => this.toggleMenu(false)
        })

        this.isOpen = false
        this.bindEvents()
    }

    bindEvents() {
        this.toggleMenuBtn.addEventListener('click', this.toggleMenu)
    }

    toggleMenu = force => {
        this.isOpen = typeof force === 'boolean' ? force : !this.isOpen

        this.nav.classList.toggle(CLASSES.isOpen, this.isOpen)
        this.toggleMenuBtn.setAttribute('aria-expanded', String(this.isOpen))
        document.body.classList.toggle(CLASSES.bodyClass, this.isOpen)

        if (this.isOpen) {
            this.focusTrap.activate()
        } else {
            this.focusTrap.deactivate()
        }
    }
}

const navElement = document.querySelector(SELECTORS.nav)
if (navElement) {
    // eslint-disable-next-line no-unused-vars
    const _ = new Navigation(navElement)
}
