import Cookies from 'js-cookie'

const SELECTORS = {
    banner: '#js-cookiebanner',
    acceptBtn: '.js-cookiebanner-accept',
    rejectBtn: '.js-cookiebanner-reject',
    revokeLink: '.js-revoke-cookie-consent'
}

class CookieBanner {
    constructor(fn, waitAccept = true) {
        // Properties
        this.cookieTimeoutDays = 365
        this.bots = /bot|crawler|spider|crawling/i
        this.cookieName = 'hasConsent'
        this.trackingCookiesNames = [
            '__utma',
            '__utmb',
            '__utmc',
            '__utmt',
            '__utmv',
            '__utmz',
            '_ga',
            '_gat',
            '_gid'
        ]
        this.callback = fn
        this.waitAccept = waitAccept || false

        // Elements
        this.banner = document.querySelector(SELECTORS.banner)
        Array.from(document.querySelectorAll(SELECTORS.revokeLink), link =>
            link.addEventListener('click', () => this.revokeConsent())
        )

        // Kick it off
        this.init()
    }

    init() {
        // Do nothing if DNT, Bot, or previously rejected cookies
        if (
            !CookieBanner.allowsTracking() ||
            this.isBot() ||
            this.hasConsent() === false ||
            !this.banner
        ) {
            return false
        }

        // User has already consented to cookies, enable tracking
        if (this.hasConsent() === true) {
            this.callback()
            return true
        }

        // show banner, pre-emptively accept cookies if configured
        this.showBanner()
        if (!this.waitAccept) {
            this.acceptCookies()
        }
    }

    showBanner() {
        // Display the Banner and bind the accept/reject actions
        const acceptBtn = this.banner.querySelector(SELECTORS.acceptBtn)
        const rejectBtn = this.banner.querySelector(SELECTORS.rejectBtn)

        this.banner.removeAttribute('hidden')

        if (acceptBtn) {
            acceptBtn.addEventListener('click', () => this.acceptCookies())
        }
        if (rejectBtn) {
            rejectBtn.addEventListener('click', () => this.rejectCookies())
        }
    }

    hideBanner() {
        this.banner.setAttribute('hidden', true)
    }

    acceptCookies() {
        Cookies.set(this.cookieName, true, { expires: this.cookieTimeoutDays })
        this.hideBanner()
        this.callback()
    }

    rejectCookies() {
        Cookies.set(this.cookieName, false, { expires: this.cookieTimeoutDays })
        this.hideBanner()
        this.deleteTrackingCookies()
    }

    hasConsent() {
        const cookieValue = Cookies.get(this.cookieName)
        switch (cookieValue) {
            case 'true':
                return true
            case 'false':
                return false
            default:
                return undefined
        }
    }

    revokeConsent() {
        Cookies.remove(this.cookieName)
        this.deleteTrackingCookies()
        window.location.reload()
    }

    isBot() {
        return this.bots.test(navigator.userAgent)
    }

    deleteTrackingCookies() {
        this.trackingCookiesNames.map(cookieName => Cookies.remove(cookieName))
    }

    static allowsTracking() {
        const dnt =
            navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack
        return dnt !== null && dnt !== undefined
            ? dnt && dnt !== 'yes' && dnt !== 1 && dnt !== '1'
            : true
    }
}

// ================================
// DEFINE TRACKING HERE
// ================================

function asyncLoadScript(src) {
    var s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = src
    var x = document.getElementsByTagName('script')[0]
    x.parentNode.insertBefore(s, x)
}
function gtag() {
    if (!window.dataLayer) {
        window.dataLayer = []
    }
    window.dataLayer.push(arguments)
}

new CookieBanner(function() {
    if (process.env.NODE_ENV === 'development') {
        return
    }

    /* eslint-disable */
    // prettier-ignore
    window.dataLayer = window.dataLayer || []
    asyncLoadScript('https://www.googletagmanager.com/gtag/js?id=G-B43FCYZT7Q')
    gtag('js', new Date())
    gtag('config', 'G-B43FCYZT7Q')
    /* eslint-enable */
})
