// MASTER JAVASCRIPT INDEX

// Polyfill for ES6, before anything else.
import 'babel-polyfill'
// focus-visible polyfill, for proper focus styling
import 'focus-visible'
// SVG External Spritesheet Polyfill
import 'svgxuse'

// Import vanilla JS index
import './vanilla'

// React Apps
const noop = () => {}
if (document.querySelector('#js-search-root')) {
    import('./react/search/app').then(noop)
}
if (document.querySelector('#js-account-root')) {
    import('./react/account/app').then(noop)
}
if (document.querySelector('#js_fund_data')) {
    import('./react/fund/app').then(noop)
}
