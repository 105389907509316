// Header Class

const SELECTORS = {
    header: '.js-header',
    search: '.js-header-search',
    toggleSearchBtn: '.js-header-search-toggle',
    closeSearchBtn: '.js-header-search-close'
}

const CLASSES = {
    searchFormOpen: 'header--search-open'
}

class Header {
    constructor(el) {
        this.header = el

        this.search = el.querySelector(SELECTORS.search)
        this.toggleSearchBtn = el.querySelector(SELECTORS.toggleSearchBtn)
        this.closeSearchBtn = el.querySelector(SELECTORS.closeSearchBtn)

        this.isSearchOpen = false

        if (this.toggleSearchBtn) {
            this.toggleSearchBtn.addEventListener(
                'click',
                this.toggleSearchForm
            )
        }
        if (this.closeSearchBtn) {
            this.closeSearchBtn.addEventListener('click', this.closeSearchForm)
        }
    }

    toggleSearchForm = () => {
        this.isSearchOpen = !this.isSearchOpen
        this.search.hidden = !this.isSearchOpen
        this.toggleSearchBtn.setAttribute(
            'aria-expanded',
            String(this.isSearchOpen)
        )
        this.header.classList.toggle(CLASSES.searchFormOpen, this.isSearchOpen)
    }

    closeSearchForm = () => {
        this.isSearchOpen = false
        this.search.hidden = true
        this.toggleSearchBtn.setAttribute('aria-expanded', 'false')
        this.header.classList.remove(CLASSES.searchFormOpen)

        // move focus to toggle btn
        this.toggleSearchBtn.focus()
    }
}

const headerElement = document.querySelector(SELECTORS.header)
if (headerElement) {
    new Header(headerElement)
}
